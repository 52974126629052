
document.addEventListener('astro:page-load', async () => {
    await import("htmx.org").then(htmx => {
        htmx.config.globalViewTransitions = true;
        htmx.process(document.body)
    })
})

import posthog from 'posthog-js'
window.posthog = posthog
if (true) {
    posthog.init('phc_Bhc0qd4wv8Y3bpMs3GojBsjibe6fq2fzjyayR05JVIs', {"api_host":"https://r2.sabal.finance","disable_session_recording":true,"autocapture":false})
}
import { init } from 'astro/virtual-modules/prefetch.js';init()